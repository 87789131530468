import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import {
  FootnoteText,
  H1,
  List,
  MediaQuery,
  SinglePhoneImageWrap,
  Text,
  WiderPhoneImage
} from '../styles'
import FullWidthImageTextCard from './FullWidthImageTextCard'

const AppDescription = styled(H1)`
  /* color: #fff; */
  margin: 30px 5px 0 5px;
  text-transform: none;
  text-align: center;
  @media ${MediaQuery.Medium} {
    margin: 40px 0;
    padding: 0 80px;
  }
  @media ${MediaQuery.Large} {
    margin: 30px 0;
    text-align: left;
    padding: 0;
  }
`

const AppDescription2 = styled(Text)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  span {
    font-weight: bold;
  }

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 0;
    margin: 0;
  }
`

const Disclaimer = styled(FootnoteText)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 20px 0;
    margin: 0;
  }
`

const AddItemCard: React.FC = () => {
  return (
    <FullWidthImageTextCard
      cardBackground="#efefef"
      layout="imageOnLeftSide"
      imageComponent={
        <SinglePhoneImageWrap>
          <StaticImage
            style={{ width: '100%' }}
            src="../../static/images/screenshots/ios/add-item-2.png"
            alt="My School Files"
          />
        </SinglePhoneImageWrap>
      }
      bodyComponent={
        <>
          <AppDescription>Adding School Items</AppDescription>
          <List>
            <li>Pick your students and school year and give it a name.</li>
            <li>
              You can add a more detailed description or add some categories.
            </li>
            <li>Upload photos or take pictures, link websites and videos.</li>
          </List>
        </>
      }
    />
    // <FullWidthVideoTextCard
    //   cardBackground="#fff"
    //   layout="videoOnRightSide"
    //   videoUrl="/static/vids/add-item.mp4"
    //   bodyComponent={
    //     <>
    //       <AppDescription>Adding School Items is SO EASY!</AppDescription>
    //       <List>
    //         <li>Pick your student and school year and give it a name.</li>
    //         <li>
    //           You can add a more detailed description or add some categories.
    //         </li>
    //         <li>
    //           Optionally upload photos or take pictures, link websites and
    //           videos.
    //         </li>
    //       </List>
    //     </>
    //   }
    // />
  )
}

export default AddItemCard
