import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from './AppStoreIcon'
import {
  blueGradientMixin,
  Colors,
  Column,
  H1,
  H2,
  MediaQuery,
  Row,
  Text
} from '../styles'
import PageContainer from './PageContainer'
import ButtonLearnMore from './ButtonLearnMore'
import { FaHeart } from 'react-icons/fa'
import ButtonSignup from './ButtonSignup'

const Hero = styled(Column)`
  padding: 40px 0;
  align-items: center;
  background: #efefef;
  background: url('/images/bg.jpg') no-repeat center;
  background-size: cover;
  /* ${blueGradientMixin} */

  flex: 1;
`

const ImageWrap = styled.div`
  width: 60%;

  /* width: 30px; */
  @media ${MediaQuery.Small} {
    width: 90%;
  }
`

const LeftSide = styled.div`
  display: flex;
  flex: 1;
  /* margin-right: 40px; */
  /* flex-direction: row; */
  justify-content: flex-start;
`

const RightSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const Wrap = styled.div`
  display: flex;
  flex: 1;
  background: ${Colors.white};
`

const AppDescription = styled(H1)`
  /* color: #fff; */
  margin: 30px 0;
  text-transform: none;
`

const AppDescription2 = styled(Text)`
  /* color: #fff; */
  display: inline-flex;
  align-items: center;
  margin: 15px 0;
  text-transform: none;
  max-width: 800px;
`

const CTA = styled(ButtonSignup)`
  /* margin: 40px 0; */
  width: 580px;
  @media ${MediaQuery.Small} {
    width: 90%;
  }
`

const Container = styled(PageContainer)`
  display: flex;

  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
`

const HeroCardLearnMoreHome: React.FC = () => {
  return (
    <Wrap>
      <Hero>
        <RightSide>
          <ImageWrap>
            <StaticImage
              style={{ width: '100%', height: '100%' }}
              src="../../static/images/hero2.png"
              alt="My School Files"
            />
          </ImageWrap>
        </RightSide>
        <LeftSide>
          <Column>
            <Container>
              <AppDescription>
                Homeschooling can be hard.
                <br /> We make it easier.
              </AppDescription>
              {/* <AppDescription2>
                <FaHeart color={Colors.pink} style={{ marginRight: 10 }} /> Made
                by homeschoolers for homeschoolers.
              </AppDescription2> */}
              <CTA />
            </Container>
          </Column>
        </LeftSide>
      </Hero>
    </Wrap>
  )
}

export default HeroCardLearnMoreHome
