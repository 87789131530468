import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import {
  FootnoteText,
  H1,
  List,
  MediaQuery,
  SinglePhoneImageWrap,
  Text
} from '../styles'
import FullWidthImageTextCard from './FullWidthImageTextCard'

const AppDescription = styled(H1)`
  /* color: #fff; */
  margin: 30px 5px 0 5px;
  text-transform: none;
  text-align: center;
  @media ${MediaQuery.Medium} {
    margin: 40px 0;
    padding: 0 80px;
  }
  @media ${MediaQuery.Large} {
    margin: 30px 0;
    text-align: left;
    padding: 0;
  }
`

const AppDescription2 = styled(Text)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  span {
    font-weight: bold;
  }

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 0;
    margin: 0;
  }
`

const Disclaimer = styled(FootnoteText)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 20px 0;
    margin: 0;
  }
`

const AssistantCard: React.FC = () => {
  return (
    <FullWidthImageTextCard
      cardBackground="#efefef"
      layout="imageOnLeftSide"
      imageComponent={
        <SinglePhoneImageWrap>
          <StaticImage
            style={{ width: '100%' }}
            src="../../static/images/screenshots/ios/assistant.png"
            alt="My School Files"
          />
        </SinglePhoneImageWrap>
      }
      bodyComponent={
        <>
          <AppDescription>Assistant - Here to Help</AppDescription>
          <List>
            <li>
              When you use the app for the first time, we share tips and tricks
              on how to best use and maximize all the features of My School
              Files.
            </li>
            <li>
              As you use the app we explain what things do and how to best
              organize your portfolio.
            </li>
            <li>These tips can be found right below different components.</li>
            <li>You can turn off the assistant at any time.</li>
          </List>
        </>
      }
    />
  )
}

export default AssistantCard
