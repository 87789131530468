import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import {
  FootnoteText,
  H1,
  List,
  MediaQuery,
  SinglePhoneImageWrap,
  Text
} from '../styles'
import FullWidthImageTextCard from './FullWidthImageTextCard'

const AppDescription = styled(H1)`
  /* color: #fff; */
  margin: 30px 5px 0 5px;
  text-transform: none;
  text-align: center;
  @media ${MediaQuery.Medium} {
    margin: 40px 0;
    padding: 0 80px;
  }
  @media ${MediaQuery.Large} {
    margin: 30px 0;
    text-align: left;
    padding: 0;
  }
`

const AppDescription2 = styled(Text)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  span {
    font-weight: bold;
  }

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 0;
    margin: 0;
  }
`

const Disclaimer = styled(FootnoteText)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 20px 0;
    margin: 0;
  }
`

const CalendarHowToCard: React.FC = () => {
  return (
    <FullWidthImageTextCard
      cardBackground="#efefef"
      layout="imageOnLeftSide"
      imageComponent={
        <SinglePhoneImageWrap>
          <StaticImage
            style={{ width: '100%' }}
            src="../../static/images/screenshots/ios/calendar-front.png"
            alt="My School Files"
          />
        </SinglePhoneImageWrap>
      }
      bodyComponent={
        <>
          <AppDescription>Calendar</AppDescription>
          <List>
            <li>Keep track of your school days, field trips, and co-ops.</li>
            <li>Tap on any day and add your agenda.</li>
            <li>Each student has their own calendar.</li>
          </List>
        </>
      }
    />

    // <FullWidthVideoTextCard
    //   cardBackground="#fff"
    //   layout="videoOnRightSide"
    //   videoUrl="/static/vids/today-agenda.mp4"
    //   bodyComponent={
    //     <>
    //       <AppDescription>Calendar</AppDescription>
    //       <List>
    //         <li>Keep track of your school days, field trips, and co-ops.</li>
    //         <li>Tap on any day and add your agenda.</li>
    //         <li>Each student has their own calendar.</li>
    //       </List>
    //     </>
    //   }
    // />
  )
}

export default CalendarHowToCard
