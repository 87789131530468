import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'
import HowItWorksMainCard from '../components/HowItWorksMainCard'
import FadeInViewport from '../components/FadeInViewport'
import CreateAccountCard from '../components/CreateAccountCard'
import SelectSchoolYearCard from '../components/SelectSchoolYearCard'
import AddFirstStudentsCard from '../components/AddFirstStudentsCard'
import DashboardCard from '../components/DashboardCard'
import CalendarHowToCard from '../components/CalendarHowToCard'
import StudentHomeCard from '../components/StudentHomeCard'
import CategorizeCard from '../components/CategorizeCard'
import AddItemCard from '../components/AddItemCard'
import FilterViewCard from '../components/FilterViewCard'
import ShareHowToCard from '../components/ShareHowToCard'
import PricingHeroCard from '../components/PricingHeroCard'
import HeroCardLearnMoreHome from '../components/HeroCardLearnMoreHome'
import AssistantCard from '../components/AssistantCard'
import PDFCard from '../components/PDFCard'

const IndexPage = () => (
  <Layout>
    <Seo title="How It Works" url="https://myschoolfiles.com/how-it-works" />
    {/* <HowItWorksMainCard /> */}
    <HeroCardLearnMoreHome />
    <FadeInViewport>
      <CreateAccountCard />
    </FadeInViewport>
    <FadeInViewport>
      <SelectSchoolYearCard />
    </FadeInViewport>
    <FadeInViewport>
      <AddFirstStudentsCard />
    </FadeInViewport>
    <FadeInViewport>
      <AssistantCard />
    </FadeInViewport>
    <FadeInViewport>
      <DashboardCard />
    </FadeInViewport>
    <FadeInViewport>
      <CalendarHowToCard />
    </FadeInViewport>

    <FadeInViewport>
      <StudentHomeCard />
    </FadeInViewport>

    <FadeInViewport>
      <AddItemCard />
    </FadeInViewport>

    {/* <FadeInViewport>
      <AddItemCard />
    </FadeInViewport> */}
    <FadeInViewport>
      <CategorizeCard />
    </FadeInViewport>
    <FadeInViewport>
      <FilterViewCard />
    </FadeInViewport>
    <FadeInViewport>
      <ShareHowToCard bgColor="#fff" />
    </FadeInViewport>
    <FadeInViewport>
      <PDFCard />
    </FadeInViewport>
    <FadeInViewport>
      <PricingHeroCard />
    </FadeInViewport>
  </Layout>
)

export default IndexPage
