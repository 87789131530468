import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import {
  FootnoteText,
  H1,
  List,
  MediaQuery,
  SinglePhoneImageWrap,
  Text,
  WiderPhoneImage
} from '../styles'
import FullWidthImageTextCard from './FullWidthImageTextCard'

const AppDescription = styled(H1)`
  /* color: #fff; */
  margin: 30px 5px 0 5px;
  text-transform: none;
  text-align: center;
  @media ${MediaQuery.Medium} {
    margin: 40px 0;
    padding: 0 80px;
  }
  @media ${MediaQuery.Large} {
    margin: 30px 0;
    text-align: left;
    padding: 0;
  }
`

const AppDescription2 = styled(Text)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  span {
    font-weight: bold;
  }

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 0;
    margin: 0;
  }
`

const Disclaimer = styled(FootnoteText)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 20px 0;
    margin: 0;
  }
`

const StudentHomeCard: React.FC = () => {
  return (
    <FullWidthImageTextCard
      cardBackground="#fff"
      layout="imageOnRightSide"
      imageComponent={
        <SinglePhoneImageWrap>
          <StaticImage
            style={{ width: '100%' }}
            src="../../static/images/screenshots/ios/student-home.png"
            alt="My School Files"
          />
        </SinglePhoneImageWrap>
      }
      bodyComponent={
        <>
          <AppDescription>School Items</AppDescription>
          <List>
            <li>Showcase your student's work through out the year.</li>
            <li>
              Organize all of your student's school items securely in the cloud
              with <span>Categories</span>
            </li>
            <li>
              Take pictures, add photos, link YouTube videos or websites:
              whatever you like.
            </li>
          </List>
        </>
      }
    />
  )
}

export default StudentHomeCard
