import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from './AppStoreIcon'
import {
  blueGradientMixin,
  Colors,
  Column,
  FootnoteText,
  H1,
  H2,
  List,
  MediaQuery,
  Row,
  SinglePhoneImageWrap,
  Text
} from '../styles'
import PageContainer from './PageContainer'
import ButtonLearnMore from './ButtonLearnMore'
import { FaHeart } from 'react-icons/fa'
import ButtonSignup from './ButtonSignup'
import FullWidthImageTextCard from './FullWidthImageTextCard'

const AppDescription = styled(H1)`
  /* color: #fff; */
  margin: 30px 5px 0 5px;
  text-transform: none;
  text-align: center;
  @media ${MediaQuery.Medium} {
    margin: 40px 0;
    padding: 0 80px;
  }
  @media ${MediaQuery.Large} {
    margin: 30px 0;
    text-align: left;
    padding: 0;
  }
`

const AppDescription2 = styled(Text)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  span {
    font-weight: bold;
  }

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 0;
    margin: 0;
  }
`

const Disclaimer = styled(FootnoteText)`
  /* color: #fff; */

  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 20px 0;
    margin: 0;
  }
`

const SelectSchoolYearCard: React.FC = () => {
  return (
    <FullWidthImageTextCard
      cardBackground="#efefef"
      layout="imageOnLeftSide"
      imageComponent={
        <SinglePhoneImageWrap>
          <StaticImage
            style={{ width: '100%' }}
            src="../../static/images/screenshots/ios/select-school-year.png"
            alt="My School Files"
          />
        </SinglePhoneImageWrap>
      }
      bodyComponent={
        <>
          <AppDescription>
            Login and Set your Current School Year
          </AppDescription>
          <List>
            <li>Impacts how we count school days, field trips, and co-ops.</li>
            <li>
              Your school year start date determines what will be included when
              you share a portfolio for a given school year.
            </li>
            <li>Student school items always belong to a single school year.</li>
            <li>
              You can modify your school year at any time in{' '}
              <span>"Settings"</span>
            </li>
          </List>
        </>
      }
    />
  )
}

export default SelectSchoolYearCard
